<template>
    <form autocomplete="off">      
        <slot>
            <div class="alert alert-danger" role="alert">
            Não existem registros a serem visualizados.
            </div>            
        </slot>    
    </form>
</template>

<script>
export default {
    methods: {

    }
}
</script>